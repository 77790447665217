import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-grid-system'
import ContainerWrapper from '../../../components/ContainerWrapper'
import Seo from "../../../components/SEO";
import jmespath from 'jmespath';
import contentEventData from './data/event-hub-filtering-test-content-data.json'
import notificationEventData from './data/event-hub-filtering-test-notification-data.json'
import workflowEventData from './data/event-hub-filtering-test-workflow-data.json'

const Style = styled.div`

  h1 {
    font-weight: 500;
  }

  h2 {
    margin-top: 1.5em;
    scroll-margin-top: 140px;
  }

  label {
    font-weight: bold;
  }

  li {
    margin-bottom: 0;
  }

  .filter-expression-label {
    font-weight: bold;
    margin-top: 1.5em;
  }

  .filter-expression {
    width: 100%;
  }

  .filter-expression-result {
    font-weight: bold;
    margin-top: 1.5em;
  }

  span.filter-result-internal-error::before {
    color: red;
    content: "Internal error";
  }

  span.filter-result-illegal-expression::before {
    color: red;
    content: "Illegal expression";
  }

  span.filter-result-invalid-json::before {
    color: red;
    content: "Invalid JSON";
  }

  span.filter-result-pass::before {
    color: green;
    content: "\\002713";
  }

  span.filter-result-filtered-out::before {
    content: "\\00274C";
  }

  textarea.event-json {
    margin-top: 1.5em;
    width: 100%;
    overflow-y:hidden;
  }

  a {
    color: rgb(0, 108, 174);
    text-decoration: underline;
  }

  a:hover, a:visited, a:active {
    font-weight: 300;
    color: currentcolor;
    text-decoration: none;
  }

`;

const FILTER_RESULT = {
  PASS: 0,
  FILTERED_OUT: 1,
  ILLEGAL_EXPRESSION: 2
}

/**
 * Returns an array of Event Hub CONTENT events, each as a <pre>{ title, key, json }</pre> object.
 */
function getContentEvents() {
  const events = [];
  contentEventData.forEach(event => { events.push(getEventItem(event.title, event.data)) });
  return events;
}

/**
 * Returns an array of Event Hub NOTIFICATION events, each as a <pre>{ title, key, json }</pre> object.
 */
function getNotificationEvents() {
  const events = [];
  notificationEventData.forEach(event => { events.push(getEventItem(event.title, event.data)) });
  return events;
}

/**
 * Returns an array of Event Hub WORKFLOW events, each as a <pre>{ title, key, json }</pre> object.
 */
function getWorkflowEvents() {
  const events = [];
  workflowEventData.forEach(event => { events.push(getEventItem(event.title, event.data)) });
  return events;
}

function getEventItem(title, json) {
  return { "title": title, "key": title.replace(/\s+/g, "-"), "json": json };
}

/**
 * Returns the currently selected event's JSON data.
 */
function getSelectedEventJson(eventType, index) {
  switch (eventType) {
    case 'content':
      return getContentEvents().at(index).json;
    case 'notification':
      return getNotificationEvents().at(index).json;
    case 'workflow':
      return getWorkflowEvents().at(index).json;
    default:
      return undefined;
  }
}

/**
 * Searches for the first element in the DOM hierarchy with a 'data-event-type' attribute and returns its value.
 */
function getEventType(element) {
  let eventType = element.dataset.eventType;
  if(eventType) {
    return eventType;
  }
  let parent = element.parentElement;
  if(parent) {
    return getEventType(parent);
  }
  return undefined;
}

/**
 * Returns the current event JSON from the given event type's textarea.
 */
function getCurrentEvent(eventType) {
  let eventTextarea = document.getElementById('event-json-' + eventType);
  let event = eventTextarea.value
  try {
    JSON.parse(event);
    return event;
  }
  catch (e) {
    return undefined;
  }
}

/**
 * Resizes the given textarea to fit its content.
 */
function resizeTextarea(element) {
  element.style.height = "auto";
  element.style.height = (element.scrollHeight) + "px";
}


function onEventSelect(event) {
  let eventType = getEventType(event.target);
  let eventJson = getSelectedEventJson(eventType, event.target.selectedIndex);
  if(eventJson) {
    let eventTextarea = document.getElementById('event-json-' + eventType);
    eventTextarea.value = JSON.stringify(eventJson, null, 2);
    resizeTextarea(eventTextarea)
  }
  updateFilterMatch(eventType);
}

function onExpressionChange(event) {
  let eventType = getEventType(event.target);
  updateFilterMatch(eventType);
}

function onJsonChange(event) {
  let eventType = getEventType(event.target);
  resizeTextarea(event.target);
  updateFilterMatch(eventType);
}


function updateFilterMatch(eventType) {
  let filterResultDisplayClass;
  // get current event JSON
  let event = getCurrentEvent(eventType);
  if(!event) {
    filterResultDisplayClass = "filter-result-invalid-json";
  }
  else {
    // get current filter expression
    let filterExpressionElement = document.getElementById('filter-expression-' + eventType);
    let filterExpression = filterExpressionElement.value;
    if (filterExpression.trim().length > 0) {
      // apply filter and update result output accordingly
      let filterResult = evaluate(event, filterExpression);
      switch (filterResult) {
        case FILTER_RESULT.PASS:
          filterResultDisplayClass = "filter-result-pass";
          break;
        case FILTER_RESULT.FILTERED_OUT:
          filterResultDisplayClass = "filter-result-filtered-out";
          break;
        case FILTER_RESULT.ILLEGAL_EXPRESSION:
          filterResultDisplayClass = "filter-result-illegal-expression";
          break;
        default:
          filterResultDisplayClass = "filter-result-internal-error"
      }
    }
  }
  if(filterResultDisplayClass) {
    document.getElementById('filter-expression-result-' + eventType).innerHTML =
      "Filter Result: <span class='" + filterResultDisplayClass + "'/>";
  }
  else {
    document.getElementById('filter-expression-result-' +eventType).innerHTML = "";
  }
}

/**
 * Returns 'FILTER_RESULT.PASS' if the given JSON string matches the given expression, 'FILTER_RESULT.FILTERED_OUT'
 * otherwise. If the given expression is illegal, it returns 'FILTER_RESULT.ILLEGAL_EXPRESSION'.
 */
function evaluate(event, expression) {
  try {
    expression = expression.trim();
    // check for restricted JMESPath syntax (keep in sync with JMESPathFilter#validate(String); https://github.com/CoreMedia/event-hub-cloud/blob/05d166f69ada6882203683ca2fbffc278abd98d8/event-hub-common-lambda/src/main/java/com/coremedia/eventhub/cloud/lamdba/common/filter/impl/JMESPathFilter.java#L37)
    if (!(expression.startsWith("[?") && expression.endsWith("]"))) {
      return FILTER_RESULT.ILLEGAL_EXPRESSION;
    }
    for (let b = 0, i = 0, c = expression.length; i < c; i++) {
      let x = expression.charAt(i);
      if (x === '[') {
        b++;
      }
      if (x === ']') {
        b--;
      }
      if (b === 0 && !(i === c - 1)) {
        return FILTER_RESULT.ILLEGAL_EXPRESSION;
      }
    }
    // perform JMESPath query match
    let result = jmespath.search(JSON.parse("[" + event + "]"), expression);
    return result && result.length !== 0 ? FILTER_RESULT.PASS : FILTER_RESULT.FILTERED_OUT;
  } catch (e) {
    return FILTER_RESULT.ILLEGAL_EXPRESSION;
  }
}


const EventHubFilterTestPage = ({ children, pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  useEffect(() => {
    resizeTextarea(document.getElementById('event-json-content'));
    resizeTextarea(document.getElementById('event-json-notification'));
    resizeTextarea(document.getElementById('event-json-workflow'));
  },[]);

  return (
    <ContainerWrapper crumbs={crumbs} withFeedback>
      <Seo
        title={"Event Hub Filter Test Page"}
        description={"Test Filter Expressions on Events"}
      />
      <Style>
        <Row>
          <Col xl={12}>
            <h1>Event Hub Filter Test Page</h1>
            <div>
              <p>
                This page ist structured into three different sections, one for each class of events:
              </p>
              <ul>
                <li key="filter-content"><a href="#content">Content</a></li>
                <li key="filter-notification"><a href="#notification">Notification</a></li>
                <li key="filter-workflow"><a href="#workflow">Workflow</a></li>
              </ul>
              <p>
                Go to the appropriate section, select an event from the list and enter a filter expression according
                to <a href="/services/event-hub-service/event-hub-filtering/">Filtering Events with the Event Hub
                Service</a>.<br/>
                After selecting an event, editing the event or changing the filter expression, the filter result will
                be recomputed.<br/>
              </p>
              <p>
                The sections from the JMESPath specification relevant for the applied filter expressions can be found here:
              </p>
              <ul>
                <li key="ref-filter-expression"><a href="https://jmespath.org/specification.html#filter-expressions">Filter Expressions</a> and</li>
                <li key="ref-function-expressions"><a href="https://jmespath.org/specification.html#function-expressions">Function Expressions</a></li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <h2 id="content">Content Events</h2>
            <div data-event-type="content">

              <div>
                <label htmlFor="event-selector-content">Event:&nbsp;</label>
                <select id="event-selector-content"
                        onChange={onEventSelect}>
                  {getContentEvents().map(e => <option key={e.key}>{e.title}</option>)}
                </select>
              </div>

              <div>
                <textarea id="event-json-content"
                          name="event-json-content"
                          className="event-json"
                          defaultValue={JSON.stringify(getContentEvents().at(0).json, null, 2)}
                          onChange={onJsonChange}>
                </textarea>
              </div>

              <div className="filter-expression-label">Filter Expression</div>
              <div>
                <em>Examples:</em>
                <ul>
                  <li key="example-content-1">[?eventClass=='CONTENT']</li>
                  <li key="example-content-2">[?eventType=='CONTENT_CREATED']</li>
                  <li key="example-content-3">[?contentType=='CMImage' || contentType=='CMArticle']</li>
                  <li key="example-content-4">[?contentTypeHierarchy && contains(contentTypeHierarchy, 'CMTeasable')]</li>
                  <li key="example-content-5">[?performerUuid=='ade56258-d4d3-3014-a7ea-42d1774b872e']</li>
                </ul>
              </div>
              <div>
                <input id="filter-expression-content"
                       className="filter-expression"
                       type="text"
                       placeholder="Filter Expression"
                       onInput={onExpressionChange}/>
              </div>
              <div id="filter-expression-result-content"
                   className="filter-expression-result">
              </div>

            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <h2 id="notification">Notification Events</h2>
            <div data-event-type="notification">

              <div>
                <label htmlFor="event-selector-notification">Event:&nbsp;</label>
                <select id="event-selector-notification"
                        onChange={onEventSelect}>
                  {getNotificationEvents().map(e => <option key={e.key}
                                                            data-event-json={JSON.stringify(e.json, null, 2)}>{e.title}</option>)}
                </select>
              </div>

              <div>
                <textarea id="event-json-notification"
                          name="event-json-notification"
                          className="event-json"
                          defaultValue={JSON.stringify(getNotificationEvents().at(0).json, null, 2)}
                          onChange={onJsonChange}>
                </textarea>
              </div>

              <div className="filter-expression-label">Filter Expression</div>
              <div>
                <em>Examples:</em>
                <ul>
                  <li key="example-notification-1">[?key=='new_comment_for_content']</li>
                  <li key="example-notification-2">[?key=='offered' && recipientEmail]</li>
                </ul>
              </div>
              <div>
                <input id="filter-expression-notification"
                       className="filter-expression"
                       type="text"
                       placeholder="Filter Expression"
                       onInput={onExpressionChange}/>
              </div>
              <div id="filter-expression-result-notification"
                   className="filter-expression-result">
              </div>

            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <h2 id="workflow">Workflow Events</h2>
            <div data-event-type="workflow">

              <div>
                <label htmlFor="event-selector-workflow">Event:&nbsp;</label>
                <select id="event-selector-workflow"
                        onChange={onEventSelect}>
                  {getWorkflowEvents().map(e => <option key={e.key}
                                                        data-event-json={JSON.stringify(e.json, null, 2)}>{e.title}</option>)}
                </select>
              </div>

              <div>
                <textarea id="event-json-workflow"
                          name="event-json-workflow"
                          className="event-json"
                          defaultValue={JSON.stringify(getWorkflowEvents().at(0).json, null, 2)}
                          onChange={onJsonChange}>
                </textarea>
              </div>

              <div className="filter-expression-label">Filter Expression</div>
              <div>
                <em>Examples:</em>
                <ul>
                  <li key="example-workflow-1">[?eventClass=='PROCESS']</li>
                  <li key="example-workflow-2">[?eventType=='TASK_OFFERED']</li>
                  <li key="example-workflow-3">[?eventType=='TASK_OFFERED' && taskDefinitionName == 'Approve']</li>
                </ul>
              </div>
              <div>
                <input id="filter-expression-workflow"
                       className="filter-expression"
                       type="text"
                       placeholder="Filter Expression"
                       onInput={onExpressionChange}/>
              </div>
              <div id="filter-expression-result-workflow"
                   className="filter-expression-result">
              </div>

            </div>
          </Col>
        </Row>
      </Style>
    </ContainerWrapper>
  )
}

export default EventHubFilterTestPage;
